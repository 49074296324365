/**
 * Panel settings
 * Routes, resources
 */

export enum Resource {
  post = 'post',
  firm = 'firm',
  advantage = 'advantage',
  announcement = 'announcement',
  advert = 'advert',
  advice = 'advice',
  chat = 'chat',
  video = 'video',
  platform = 'platform',
  contact = 'contact',
  entry = 'entry',
  slide = 'slide',
  user = 'user',
  foodmenu = 'foodmenu',
  survey = 'survey',
  notice = 'notice',
}
