import type { NextPage, NextPageContext } from 'next';
import LayoutAuth from 'components/layouts/LayoutAuth';
import { getSession, getCsrfToken } from 'next-auth/react';
import FormSignin from 'components/forms/FormSignin';
import routes from 'config/routes';

export type PageSigninProps = {
  csrfToken: string;
};

export default function PageSignin({ csrfToken }: NextPage & PageSigninProps) {
  return (
    <LayoutAuth>
      <FormSignin csrfToken={csrfToken} />
    </LayoutAuth>
  );
}

PageSignin.getInitialProps = async (context: NextPageContext) => {
  const { req, res } = context;
  const session = await getSession({ req });

  if (session && res) {
    const location = session?.accessToken ? routes.dashboard.href : routes.signin.href;
    res.writeHead(302, { Location: location });
    res.end();
    return;
  }

  return { csrfToken: await getCsrfToken(context) };
};
