/* eslint-disable @typescript-eslint/no-explicit-any */
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Snackbar } from '@mui/material';
import { getErrorMessage } from 'includes/utils';

export type MessageProps = {
  message?: any;
};

const Message = ({ message }: MessageProps) => {
  if (!Array.isArray(message)) return <span>{message}</span>;
  return (
    <ul className="list-feedback">
      {message.map((val, index) => (
        <li key={index}>{val}</li>
      ))}
    </ul>
  );
};

export type ActionProps = {
  onClose?: () => void;
};

const Action = ({ onClose }: ActionProps) => {
  if (!onClose) return null;
  return (
    <IconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
      <CloseIcon />
    </IconButton>
  );
};

export type FeedbackProps = MessageProps &
  ActionProps & {
    snackbar?: boolean;
    fullWidth?: boolean;
    severity?: 'error' | 'info' | 'success' | 'warning';
    mb?: number;
  };

export default function Feedback({
  snackbar,
  message,
  fullWidth = false,
  severity = 'info',
  onClose,
  mb = 2,
}: FeedbackProps) {
  message = getErrorMessage(message);
  if (!message) {
    return null;
  }

  if (snackbar) {
    return (
      <Snackbar
        open={true}
        autoHideDuration={6000}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert action={<Action onClose={onClose} />} severity={severity}>
          <Message message={message} />
        </Alert>
      </Snackbar>
    );
  }

  if (!fullWidth) {
    return (
      <Box mb={mb}>
        <Alert action={<Action onClose={onClose} />} severity={severity}>
          <Message message={message} />
        </Alert>
      </Box>
    );
  }

  return (
    <Box
      sx={(theme) => ({
        position: 'absolute',
        zIndex: 10,
        p: 5,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        height: '100%',
        ...theme.custom.flexCenter,
      })}
    >
      <Box>
        <Alert action={<Action onClose={onClose} />} severity={severity}>
          <Message message={message} />
        </Alert>
      </Box>
    </Box>
  );
}
