import setWith from 'lodash/setWith';
import isEmpty from 'lodash/isEmpty';
import clone from 'lodash/clone';
import qs from 'qs';
import dayjsInstance from 'includes/dayjsInstance';

export const hasItems = (arr: any): boolean => Array.isArray(arr) && arr.length > 0;
export const hasNoItems = (arr: any): boolean => !hasItems(arr);

export const objectToQueryString = (params?: Record<string, any>): string => {
  if (isEmpty(params)) return '';
  return params ? `?${qs.stringify(params, { encode: false, skipNulls: true })}` : '';
};

export const dt = (val: any, def?: any, checkValid?: boolean): any => {
  if (checkValid && !dayjsInstance(val).isValid()) return def || null;
  return val ? dayjsInstance(val).format('DD/MM/YYYY') : def || null;
};

export const getDateTime = (val: any, def?: any, checkValid?: boolean): any => {
  if (checkValid && !dayjsInstance(val).isValid()) return def || null;
  return val ? dayjsInstance(val).format('DD/MM/YYYY H:mm') : def || null;
};

export const translateObject = (key: string, object: object): string | null => {
  return object[key] || null;
};

export const translateValue = (key: string, value: any, object: object): any => {
  if (typeof object[key] === 'function') return object[key](value);
  return object[value] || value;
};

export const stringToColor = (string: string): string => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const stringAvatar = (name?: string): string => {
  if (!name) return '';
  const splited = name.split(' ');
  return splited.length > 1 ? `${splited[0][0]}${splited[1][0]}` : `${splited[0][0]}`;
};

export const isSuccess = (statusCode: number): boolean => Math.floor(statusCode / 100) === 2;

export const isError = (statusCode: number): boolean =>
  !statusCode || Math.floor(statusCode / 100) !== 2;

export const getErrorMessage = (message: any): string[] | string | null => {
  if (!message) return null;
  if (message.errors && typeof message.errors === 'object') message = Object.values(message.errors);
  if (message?.message) message = message.message;
  if (!Array.isArray(message)) return message;
  message = message.flat();
  return message;
};

export const getErrorTitle = (message: any): string => {
  message = getErrorMessage(message);
  if (Array.isArray(message)) return message.join('\n');
  return message ? message : 'Error';
};

export function humanFileType(name: string): string {
  return typeof name === 'string' ? name.split('.').pop().toLocaleUpperCase() : '';
}

const getFilenameFromContentDisposition = (res: Response) => {
  let filename = null;

  const disposition = res.headers.get('content-disposition');

  if (disposition?.includes('attachment')) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches?.[1]) {
      filename = matches[1].replace(/['"]/g, '');
      // Sometimes the filename comes in a URI encoded format so decode it
      filename = decodeURIComponent(filename);
      // Sometimes the filename starts with UTF-8, remove that
      filename = typeof filename === 'string' ? filename.replace(/^UTF-8/i, '').trim() : '';
    }
  }

  return filename;
};

export const getFileFromUrl = async (url: string): Promise<File> => {
  const fileRes = await fetch(url, { mode: 'no-cors' });
  const blob = await fileRes.blob();

  let fileName = getFilenameFromContentDisposition(fileRes);
  if (!fileName) fileName = url.split('/').pop();

  return new File([blob], fileName, { type: blob.type });
};

export const makeCouponCode = (length = 7) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const setTo = (state, key, value) => {
  return setWith(clone(state), key, value, clone);
};
