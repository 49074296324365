import { useController } from 'react-hook-form';
import { IInputText } from 'includes/types';
import { TextField } from '@mui/material';

export default function InputText({
  name,
  control,
  label,
  type = 'text',
  readonly = false,
  size = 'medium',
  mb = 2,
  minRows = 3,
  maxRows = 6,
  inputProps,
}: IInputText) {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ control, name });

  const errorMessage = error?.message || null;

  return (
    <TextField
      size={size}
      disabled={readonly}
      minRows={minRows}
      maxRows={maxRows}
      multiline={type === 'textarea'}
      type={type === 'textarea' ? 'text' : type}
      sx={{ mb }}
      helperText={errorMessage}
      error={!!errorMessage}
      onChange={onChange}
      value={value ? value : ''}
      label={label}
      InputProps={inputProps}
    />
  );
}
