/**
 * this file should export
 * resources and links
 */
import { Route, RouteWithGet } from 'includes/types';
import { Resource } from 'config/_app';

type ResourceNewKeys = `${Resource}_new`;
type ResourceEditKeys = `${Resource}_edit`;
type ResourceShowKeys = `${Resource}_show`;

export type Routes = Record<Resource, Route> &
  Record<ResourceNewKeys, Route> &
  Record<ResourceEditKeys, RouteWithGet> &
  Record<ResourceShowKeys, RouteWithGet> & {
    signin: Route;
    signout: Route;
    dashboard: Route;
    profile: Route;
    settings: Route;
    category: Route;
    advantagePrize: Route;
    brandValuePage: Route;
    registration: Route;
    signinflow: Route;
    surveyResult: Route;
  };

const signin = { href: '/signin', title: 'title.signin' };
const signout = { href: '/signout', title: 'title.signout' };
const dashboard = { href: '/dashboard', title: 'title.dashboard' };
const profile = { href: '/profile', title: 'title.profile' };
const settings = { href: '/settings', title: 'title.settings' };
const category = { href: '/settings/category', title: 'title.category' };
const advantagePrize = { href: '/settings/advantage-prize', title: 'title.advantage_prize' };
const brandValuePage = { href: '/settings/brand-value-page', title: 'title.brand_value_page' };
const registration = { href: '/settings/registration', title: 'title.registration' };
const signinflow = { href: '/settings/signinflow', title: 'title.signinflow' };
const surveyResult = { href: '/survey-result', title: 'title.survey_result' };

const routes = {
  signin,
  signout,
  dashboard,
  profile,
  settings,
  category,
  advantagePrize,
  brandValuePage,
  registration,
  signinflow,
  surveyResult,
};

Object.values(Resource).map((resource) => {
  routes[`${resource}`] = { href: `/${resource}`, title: `title.${resource}` };
  routes[`${resource}_new`] = { href: `/${resource}/new`, title: `title.${resource}_new` };
  routes[`${resource}_edit`] = {
    href: `/${resource}/[id]/edit`,
    title: `title.${resource}_edit`,
    getHref: (idOrSlug: string): string => `/${resource}/${idOrSlug}/edit`,
  };
  routes[`${resource}_show`] = {
    href: `/${resource}/[id]/show`,
    title: `title.${resource}_show`,
    getHref: (idOrSlug: string): string => `/${resource}/${idOrSlug}/show`,
  };
});

export default routes as Routes;
