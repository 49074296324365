import { SchemaInputType } from 'includes/enums';
import { ArraySchema, NumberSchema, StringSchema } from 'yup';
import { RequiredBooleanSchema } from 'yup/lib/boolean';
import { MixedSchema } from 'yup/lib/mixed';
import { stripHtml } from 'string-strip-html';
import { SchemaField } from 'includes/enums';
import { ObjectShape } from 'yup/lib/object';
import { Resource } from 'config/_app';
import yup from 'schemas/_yup';
import get from 'lodash/get';
import { cityOptions } from './_cities';
import { SelectOptions } from 'includes/types';

/**
 * @sse https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
 */
export type AcceptedFiles = {
  /** File MIME Type */
  [key: string]: {
    /** File extension starting with dot @ex '.pdf' */
    ext: string;
    /** Maximum file size in bytes */
    max: number;
  };
};

//const megaBytesToBytes = Math.pow(1024, 2);
//const acceptedFiles: AcceptedFiles = {
//  'image/jpeg': {max: 4 * megaBytesToBytes, ext: '.jpeg'},
//  'image/jpg': {max: 4 * megaBytesToBytes, ext: '.jpg'},
//  'application/pdf': {max: 5 * megaBytesToBytes, ext: '.pdf'},
//  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {max: 5 * megaBytesToBytes, ext: '.xlsx'},
//  'application/vnd.ms-excel': {max: 5 * megaBytesToBytes, ext: '.xls'},
//};

type YStringSchema = StringSchema<string, any, string>;
type YNumberSchema = NumberSchema<number, any, number>;
type YArraySchema = ArraySchema<MixedSchema<any, any, any>, any, any[], any[]>;
type YMixedSchema = MixedSchema<any, any, any>;

export const string = yup.string().default('').trim().nullable();

export const numberBase = yup.number().default(0);

export const number = numberBase.meta({
  field: SchemaField.InputText,
  type: SchemaInputType.number,
});

export const text = string.meta({ field: SchemaField.InputText, type: SchemaInputType.text });

export const phone = string.meta({
  field: SchemaField.InputPhone,
  type: SchemaInputType.text,
  mask: '999-999-9999',
});

export const currency = string.meta({
  field: SchemaField.InputCurrency,
  type: SchemaInputType.text,
});

export const textarea = string.meta({
  field: SchemaField.InputText,
  type: SchemaInputType.textarea,
  maxRows: 100,
});

export const coupon = yup.mixed().meta({
  field: SchemaField.InputCoupon,
  type: SchemaInputType.text,
});

export const date = string.meta({ field: SchemaField.InputDate });
export const dateTime = string.meta({ field: SchemaField.InputDateTime });

export const email = string.meta({ field: SchemaField.InputText, type: SchemaInputType.email }).email();

export const yesNo = string.meta({ field: SchemaField.InputSwitch }).oneOf(['', 'no', 'yes']);

export const password = string
  .label('Parola')
  .meta({ field: SchemaField.InputPassword, type: SchemaInputType.password })
  .min(8)
  .required();

export const url = string.meta({ field: SchemaField.InputText, type: SchemaInputType.url });
//.matches(
//  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
//  'URL formatı hatalı'
//);

export const embed = string.meta({ field: SchemaField.InputEmbed, type: SchemaInputType.url });
export const embedYoutube = string
  .meta({ field: SchemaField.InputEmbed, type: SchemaInputType.text })
  .matches(/^[a-zA-Z0-9_-]{11}$/, 'Youtube Video ID formatı hatalı');

export const confirm = yup
  .bool()
  .meta({ field: SchemaField.InputCheckbox, type: SchemaInputType.checkbox })
  .oneOf([true], 'Onayı gereklidir')
  .default(false)
  .required();

export const makeYupOptions = (options: SelectOptions, enumLike) => {
  if (enumLike) {
    options = [...options, ...Object.keys(enumLike).map((key) => ({ value: key, label: enumLike[key] }))];
  }

  return options.filter((option) => !!option);
};

export const select = (options: SelectOptions, enumLike) => {
  options = makeYupOptions(options, enumLike);

  return string
    .meta({ field: SchemaField.InputSelect, options: options })
    .oneOf(options.map((o) => o.value, get(options, '0.label')));
};

export const resource = (resource: Resource) => {
  return string.meta({ field: SchemaField.InputResource, resource });
};

export const users = () => {
  return yup.array().default([]).nullable().meta({ field: SchemaField.InputUsers });
};

export const repeater = (object: ObjectShape, config?: object) => {
  return yup
    .array()
    .meta({ field: SchemaField.Repeater, ...config })
    .of(yup.object({ id: string.label('id').default('').nullable(), ...object }));
};

export const category = string.meta({ field: SchemaField.InputSelect });

export const editor = string
  .meta({ field: SchemaField.InputEditor })
  .test('required', 'Description is required', (value) => {
    return value && stripHtml(value).result.length > 0;
  })
  .test('maxLength', 'Maximum character limit is 10000', (value) => {
    return value && stripHtml(value).result.length <= 10000;
  });

const tenMb = 10 * 1024;

export const imageBase = yup.mixed().meta({
  field: SchemaField.InputImage,
  type: SchemaInputType.file,
  accept: 'image/*',
  height: 150,
  mb: 0,
});

// .required() not working when its empty value return [null]
export const image = imageBase
  .test('fileSize', 'Görsel zorunlu alandır.', (value) => {
    value = Array.isArray(value) ? value[0] : value;
    return !!value;
  })
  .test('fileSize', 'Dosya boyutu çok büyük. Max: 10MB.', (value) => {
    value = Array.isArray(value) ? value[0] : value;
    if (typeof value === 'string') return true;
    return value && get(value, '0.size') >= tenMb;
  })
  .test('fileType', 'Desteklenmeyen dosya formatı.', (value) => {
    value = Array.isArray(value) ? value[0] : value;
    if (typeof value === 'string') return true;
    return value && ['image/jpeg', 'image/png', 'image/jpg'].includes(get(value, '0.type'));
  });

export const images = ({ label = 'Görsel' }: { label?: string }) => {
  return yup
    .array()
    .meta({
      field: SchemaField.InputFileUploadMulti,
      type: SchemaInputType.file,
      mb: 0,
      accept: '.jpeg,.jpg,.png',
      iconSize: 24,
      width: 71,
      height: 71,
    })
    .of(imageBase)
    .test('featured', `${label} zorunlu alandır`, (value) => {
      value = Array.isArray(value) ? value.filter((v) => !!v) : [];
      return value.length > 0;
    });
};

export const fileBase = yup.mixed().meta({
  field: SchemaField.InputFile,
  type: SchemaInputType.file,
  accept: '*',
  height: 150,
  mb: 0,
});

// .required() not working when its empty value return [null]
export const file = fileBase.test('fileSize', 'Dosya boyutu çok büyük. Max: 10MB.', (value) => {
  value = Array.isArray(value) ? value[0] : value;
  if (!value || typeof value === 'string') return true;
  return value && get(value, '0.size') < tenMb;
});

export const documents = () => {
  return yup
    .array()
    .meta({
      field: SchemaField.InputDocuments,
      type: SchemaInputType.file,
      accept: '*',
    })
    .of(fileBase);
};

export const questions = yup
  .mixed()
  .meta({ field: SchemaField.InputQuestions })
  .test('question_length', 'Anket için soru eklenmesi zorunludur.', (value) => {
    return Array.isArray(value) && value.length > 0;
  });

export const linking = yup.mixed().meta({ field: SchemaField.InputLinking });

interface Fields {
  url: YStringSchema;
  embed: YStringSchema;
  yesNo: YStringSchema;
  repeater: (object: ObjectShape, config?: object) => YArraySchema;
  resource: (resource: Resource) => YStringSchema;
  users: () => YArraySchema;
  embedYoutube: YStringSchema;
  date: YStringSchema;
  dateTime: YStringSchema;
  text: YStringSchema;
  currency: YStringSchema;
  email: YStringSchema;
  textarea: YStringSchema;
  editor: YStringSchema;
  number: YNumberSchema;
  phone: YStringSchema;
  message: YStringSchema;
  name: YStringSchema;
  confirm: RequiredBooleanSchema<boolean, any>;
  city: YStringSchema;
  district: YStringSchema;
  password: YStringSchema;
  passwordConfirmation: YStringSchema;
  category: YStringSchema;
  select: (options: SelectOptions, enumLike?: Record<string, string | number>) => YStringSchema;
  images: (params: { label?: string }) => YArraySchema;
  image: YMixedSchema;
  file: YMixedSchema;
  documents: () => YArraySchema;
  questions: YMixedSchema;
  linking: YMixedSchema;
  coupon: YMixedSchema;
}

export const fields: Fields = {
  url,
  embed,
  yesNo,
  repeater,
  resource,
  users,
  currency,
  embedYoutube,
  text,
  date,
  dateTime,
  images,
  file,
  documents,
  image,
  questions,
  linking,
  select,
  textarea,
  number,
  editor,
  confirm,
  email: email.label('E-Posta Adresi'),
  category: category.label('Kategori'),
  phone: phone.label('Telefon Numarası'),
  message: textarea.label('İletiniz'),
  name: text.label('Ad Soyad'),
  city: select([{ value: '', label: 'İl Seçiniz' }, ...cityOptions], null).label('İl'),
  district: string.label('İlçe'),
  password,
  passwordConfirmation: password
    .label('Yeni Parola Tekrar')
    .oneOf([yup.ref('password'), null], 'Parolalar aynı olmalıdır.'),
  coupon,
};
