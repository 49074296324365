export enum ImageSource {
  logoDefault = '/logo/aailem-logo-default.svg',
  logoWhite = '/logo/aailem-logo-white.svg',

  back = '/icons/arrow-left.svg',
  edit = '/icons/edit.svg',
  addBold = '/icons/add-bold.svg',

  authBg = '/auth-bg.svg',

  sampleFoodMenu = '/sample_food_menu.xlsx',
}
