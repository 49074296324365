import { SchemaField } from 'includes/enums';
import * as yup from 'yup';
import yupLocaleTr from 'yup-locale-tr';
import { AnyObject, Maybe } from 'yup/lib/types';

yup.setLocale(yupLocaleTr);

yup.addMethod<yup.StringSchema>(yup.string, 'readonly', function () {
  const prevMeta = this.meta();
  return this.meta({ ...prevMeta, readonly: true });
});

yup.addMethod<yup.NumberSchema>(yup.number, 'readonly', function () {
  const prevMeta = this.meta();
  return this.meta({ ...prevMeta, readonly: true });
});

yup.addMethod<yup.StringSchema>(yup.string, 'col', function (col) {
  const prevMeta = this.meta();
  return this.meta({ ...prevMeta, col });
});

yup.addMethod<yup.NumberSchema>(yup.number, 'col', function (col) {
  const prevMeta = this.meta();
  return this.meta({ ...prevMeta, col });
});

yup.addMethod<yup.StringSchema>(yup.string, 'hidden', function () {
  const prevMeta = this.meta();
  return this.meta({ ...prevMeta, field: SchemaField.InputHidden });
});

yup.addMethod<yup.NumberSchema>(yup.number, 'hidden', function () {
  const prevMeta = this.meta();
  return this.meta({ ...prevMeta, field: SchemaField.InputHidden });
});

declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    readonly(): StringSchema<TType, TContext>;
    hidden(): StringSchema<TType, TContext>;
    col(val: number): StringSchema<TType, TContext>;
  }

  interface NumberSchema<
    TType extends Maybe<number> = number | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    readonly(): NumberSchema<TType, TContext>;
    hidden(): NumberSchema<TType, TContext>;
    col(col: number): NumberSchema<TType, TContext>;
  }
}

export default yup;
