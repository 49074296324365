import yup from 'schemas/_yup';
import { fields } from 'schemas/_common';

export const schema = yup.object({
  email: fields.email.required(),
  password: fields.password,
  csrfToken: fields.text,
});

export type TSignin = yup.InferType<typeof schema>;
