import React from 'react';
import { Input } from '@mui/material';
import InputText from 'components/inputs/InputText';
import Feedback from 'components/base/Feedback';
import { useForm, useFormState } from 'react-hook-form';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/router';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema, TSignin } from 'schemas/signin';
import { PageSigninProps } from 'pages/signin';
import LoadingButton from '@mui/lab/LoadingButton';
import InputPassword from 'components/inputs/InputPassword';
import routes from 'config/routes';
import axios from 'axios';
import { isError } from 'includes/utils';
import InputRecaptcha from 'components/inputs/InputRecaptcha';

const defaultValues = { email: '', password: '' };

export default function FormSignin({ csrfToken }: PageSigninProps) {
  const router = useRouter();
  const ref = React.useRef<any>();
  const [feedback, setFeedback] = React.useState<string>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const methods = useForm<TSignin>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { handleSubmit, control } = methods;
  const { isSubmitting } = useFormState({ control });

  const onSubmit = async (data: TSignin) => {
    setLoading(true);

    const recaptcha = await ref?.current?.executeAsync();
    const { status } = await axios.post('/api/siteverify', { recaptcha }).catch((err) => err.response);

    if (isError(status)) {
      setFeedback('reCaptcha doğrulaması geçersiz');
      ref?.current?.reset();
      setLoading(false);
      return;
    }

    const res = await signIn('credentials', {
      redirect: false,
      email: data.email,
      password: data.password,
      callbackUrl: `${window.location.origin}`,
    });

    if (res?.error) {
      setFeedback(decodeURIComponent(res?.error));
      ref?.current?.reset();
      setLoading(false);
    } else {
      router.push(routes.dashboard.href);
    }
  };

  return (
    <form method="post" action="/api/auth/signin/credentials">
      <Feedback message={feedback} onClose={() => setFeedback(null)} />
      <Input name="csrfToken" type="hidden" defaultValue={csrfToken} />
      <InputText name="email" type="email" control={control} label="Şirket Mailiniz" />
      <InputPassword name="password" control={control} label="Şifre" />
      <InputRecaptcha ref={ref} />
      <LoadingButton loading={loading || isSubmitting} onClick={handleSubmit(onSubmit)} fullWidth>
        Giriş Yap
      </LoadingButton>
    </form>
  );
}
