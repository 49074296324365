import NextImage, { ImageProps } from 'next/image';
import clsx from 'clsx';
import get from 'lodash/get';

/**
 * @see https://nextjs.org/docs/api-reference/next/image
 */
//const loader: ImageLoader = ({ src, width, quality }: ImageLoaderProps) => {
//  return `${src}?` + serialize({ width, quality: quality || 100 });
//};

export type DisplayImageProps = Omit<ImageProps, 'src'> & {
  src?: string | { id: number; url: string }[];
  image?: object;
  alt: string;
  className?: string;
  ratio?: '1x1' | '4x3' | '16x9' | '21x9';
  layout?: 'fill' | 'intrinsic' | 'fixed' | 'responsive';
  size?: number;
  unoptimized?: boolean;
};

/**
 * DisplayImage
 * @see https://nextjs.org/docs/api-reference/next/image
 */
export default function DisplayImage({
  src,
  alt,
  className,
  ratio,
  size,
  width,
  height,
  objectFit,
  objectPosition,
  layout = 'fill',
  unoptimized,
}: DisplayImageProps) {
  if (Array.isArray(src)) src = get(src, '0.url', null) as string;
  if (!src) src = '/placeholder.jpg';
  src = src.replace('http://', 'https://');

  if (size) {
    width = size;
    height = size;
  }

  className = clsx('img-fluid', ratio && `ratio ratio-${ratio}`, className);

  const nextImageProps: ImageProps = {
    className,
    layout,
    objectFit,
    objectPosition,
    alt,
    src,
  };

  if (width && height) {
    nextImageProps.width = width;
    nextImageProps.height = height;
  }

  if (unoptimized) nextImageProps.unoptimized = unoptimized;
  return <NextImage {...nextImageProps} />;
}
