import { Box, Card, CardContent, Typography } from '@mui/material';
import DisplayImage from 'components/display/DisplayImage';
import { ImageSource } from 'includes/imageSource';
import React from 'react';

export type LayoutAuthProps = {
  children: React.ReactNode;
};

export default function LayoutAuth({ children }: LayoutAuthProps) {
  return (
    <Box>
      <DisplayImage
        src={ImageSource.authBg}
        layout="fill"
        objectFit="cover"
        objectPosition="center"
        alt={''}
      />
      <Box
        sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Box>
          <Box display="flex" justifyContent="center" mb={1.5}>
            <DisplayImage
              src={ImageSource.logoDefault}
              width={170}
              height={80}
              layout="fixed"
              alt={''}
            />
          </Box>
          <Box sx={{ position: 'relative', zIndex: 5 }}>
            <Typography variant="h5" color="primary" textAlign="center" mb={6}>
              Bi’ Tıkla Yanında
            </Typography>
            <Card sx={{ width: 412 }}>
              <CardContent>{children}</CardContent>
            </Card>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
