import React from 'react';
import { Controller } from 'react-hook-form';
import { IInputText } from 'includes/types';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// type={values.showPassword ? 'text' : 'password'}

const Adornment = ({ show, onClick, onMouseDown }) => (
  <InputAdornment position="end">
    <IconButton
      aria-label="toggle password visibility"
      onClick={onClick}
      onMouseDown={onMouseDown}
      edge="end"
    >
      {show ? <VisibilityOff /> : <Visibility />}
    </IconButton>
  </InputAdornment>
);

export default function InputPassword({
  name,
  control,
  label,
  readonly = false,
  size = 'medium',
  mb = 2,
  minRows = 3,
  maxRows = 6,
}: IInputText) {
  const [show, setShow] = React.useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShow(!show);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          size={size}
          disabled={readonly}
          minRows={minRows}
          maxRows={maxRows}
          type={show ? 'text' : 'password'}
          sx={{ mb }}
          helperText={error ? error.message : null}
          error={!!error}
          onChange={onChange}
          value={value ? value : ''}
          label={label}
          InputProps={{
            endAdornment: (
              <Adornment
                show={show}
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              />
            ),
          }}
        />
      )}
    />
  );
}
