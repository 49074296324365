export enum UserRole {
  admin = 'admin',
  editor = 'editor',
  employee = 'employee',
  coupon_manager = 'coupon_manager',
  relative_father = 'relative_father',
  relative_mother = 'relative_mother',
  relative_child = 'relative_child',
  relative_spouse = 'relative_spouse',
  relative_other = 'relative_other',
}

export enum UserRoleLabel {
  admin = 'Admin',
  editor = 'Editor',
  employee = 'Çalışan',
  coupon_manager = 'Kupon Yöneticisi',
  relative_father = 'Babam',
  relative_mother = 'Annem',
  relative_child = 'Çoçuğum',
  relative_spouse = 'Eşim',
  relative_other = 'Diğer',
}

export enum Departments {
  'Genel Yönetim' = 'Genel Yönetim',
  HBT = 'HBT',
  UGES = 'UGES',
  SST = 'SST',
  REHİS = 'REHİS',
  MGEO = 'MGEO',
}

export enum Gender {
  male = 'Erkek',
  female = 'Kadın',
}

export enum SchemaField {
  InputHidden = 'InputHidden',
  InputText = 'InputText',
  InputPassword = 'InputPassword',
  InputPhone = 'InputPhone',
  InputResource = 'InputResource',
  InputCurrency = 'InputCurrency',
  InputEmbed = 'InputEmbed',
  InputEditor = 'InputEditor',
  InputCheckbox = 'InputCheckbox',
  InputSwitch = 'InputSwitch',
  InputDate = 'InputDate',
  InputDateTime = 'InputDateTime',
  InputSelect = 'InputSelect',
  InputFileUploadMulti = 'InputFileUploadMulti',
  InputImage = 'InputImage',
  InputFile = 'InputFile',
  Repeater = 'Repeater',
  InputQuestions = 'InputQuestions',
  InputDocuments = 'InputDocuments',
  InputCoupon = 'InputCoupon',
  InputLinking = 'InputLinking',
  InputUsers = 'InputUsers',
}

export enum SchemaInputType {
  file = 'file',
  checkbox = 'checkbox',
  text = 'text',
  url = 'url',
  email = 'email',
  textarea = 'textarea',
  number = 'number',
  password = 'password',
}

export enum SettingName {
  advantage_prize = 'advantage_prize',
  brand_value_page = 'brand_value_page',
  registration = 'registration',
  signinflow = 'signinflow',
}

export enum SigninFlow {
  email = 'email',
  phone = 'phone',
  email_to_phone = 'email_to_phone',
}

export enum RoyaltyReason {
  register = 'register',
  profile_update = 'profile_update',
  post_create = 'post_create',
  advert_create = 'advert_create',
  advice_create = 'advice_create',
  survey_create = 'survey_create',
}

export enum RoyaltyPoint {
  register = 10,
  profile_update = 15,
  post_create = 20,
  advert_create = 20,
  advice_create = 15,
  survey_create = 20,
}
