import React from 'react';
import { Box } from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();
const RECAPTCHA_SITE = publicRuntimeConfig.NEXT_PUBLIC_RECAPTCHA_SITE;

const InputRecaptcha = React.forwardRef((props, ref: any) => {
  const onRecaptchaChange = (captchaCode) => {
    if (!captchaCode) return;
    ref?.current?.reset();
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <ReCAPTCHA
        ref={ref}
        size="invisible"
        badge="bottomleft"
        hl="tr"
        sitekey={RECAPTCHA_SITE}
        onChange={onRecaptchaChange}
      />
    </Box>
  );
});

InputRecaptcha.displayName = 'InputRecaptcha';

export default InputRecaptcha;
