import dayjs from 'dayjs';
import trTr from 'dayjs/locale/tr';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

/** @see https://www.php.net/manual/en/timezones.php */
const defaultTimezone = 'Europe/Istanbul';

dayjs.locale(trTr);
dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault(defaultTimezone);
dayjs.tz.guess();

const dayjsInstance = (date?: dayjs.ConfigType) => dayjs(date).utc(true).tz();

export default dayjsInstance;
